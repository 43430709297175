<template>
  <div
    v-if="cart || quote"
    v-responsive-classes
    :class="{ 'no-header': !showHeader }"
    class="cart-wrapper relative flex-column width-expand overflow-hidden">
    <card
      v-if="showHeader"
      class="cart-header overflow-hidden flex-column padding-remove border-remove box-shadow-1 z-level-2 relative">
      <template #body>
        <div class="card__body padding-remove margin-remove">
          <div
            class="flex-row flex-between flex-gap-l flex-middle padding-m bg-inverse c-bg">
            <div class="flex-column width-expand">
              <div class="text-l flex-between flex-wrap width-expand">
                <span class="inline-block text-bold">
                  {{ translateUcFirst("ecommerce.cart.total") }}&nbsp; ({{
                    translateNumber(totalItemQuantity)
                  }})&nbsp; {{ translateUcFirst("ecommerce.cart.items") }}: &nbsp;
                </span>
                <span class="inline-block text-bold">
                  {{
                    translateNumber(computedQuote.grandTotal, "currency", {
                      currency: computedQuote.currency,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="showHeaderFeatures" class="cart-header__features padding-m">
            <transition enter-active-class="animate__fadeIn animate--fast">
              <div v-show="messagesExpanded" class="">
                <slot name="header-features">
                  <div
                    v-for="(message, index) in messages"
                    :key="index"
                    class="text-s flex-column gap-s">
                    <icon-text :icon="message.icon">{{ message.content }}</icon-text>
                  </div>
                </slot>
              </div>
            </transition>
          </div>
        </div>
      </template>
    </card>

    <div
      class="items-container z-level-0 static overflow-y-auto"
      style="max-height: 50vh">
      <div
        v-for="(item, index) in computedQuote.items"
        v-if="!isEmpty"
        :key="index"
        class="cart-item padding-m-horizontal padding-l-vertical flex-column flex-gap-m">
        <div class="cart-item__desc-row flex-row gap-m">
          <div style="max-height: 150px">
            <img
              class="cart-item__image max-size-parent border-thin"
              :src="item.cartItem.Product.introImage"
              :alt="
                translate('core.genericAlt.imageOf', {
                  thing: item.cartItem.Product.name,
                })
              "
              style="" />
          </div>
          <div class="flex-column flex-gap-s">
            <div class="text-bold text-l">{{ item.cartItem.Product.name }}</div>
            <slot
              name="cart-item-extra"
              v-bind="{ item, product: item.cartItem.Product }">
              <div class="text-s flex-row flex-gap-s">
                <!--
                <span><span class="text-bold">Color: </span>red</span>
                <span><span class="text-bold">Size: </span>2XL</span>
                -->
              </div>
            </slot>
          </div>
        </div>
        <slot name="cart-item-controls" v-bind="{ item, product: item.cartItem.Product }">
          <div
            class="cart-item__control-row flex-row flex-stretch flex-gap-m flex-between">
            <div class="flex-column text-xs gap-s">
              <slot
                name="cart-item-quantity"
                v-bind="{ item, product: item.cartItem.Product }">
                <div v-if="!readOnly" class="flex-row flex-gap-m">
                  <form-button
                    size="s"
                    :disabled="true"
                    icon="pencil"
                    :show-slot="false"
                    :slim="true"></form-button>
                  <div class="block">
                    <form-input
                      v-model="item.quantity"
                      :disabled="readOnly"
                      size="s"
                      class="inline-block quantity-controls max-width-100"
                      controls-class="border-remove"
                      type="justoppUnits"
                      :step-size="
                        item.cartItem.Product.JustoppDealProductType.maximumQuantity
                      "
                      :maximum="
                        item.cartItem.Product.JustoppDealProductType.maximumQuantity
                      "
                      :minimum="
                        item.cartItem.Product.JustoppDealProductType.minimumQuantity
                      "
                      @update:model-value="updateCart(item)"></form-input>
                    <!--    <span class="bold block margin-xs-top text-center ">units</span> -->
                  </div>
                </div>
                <span v-else class="bold block margin-xs-top text-center text-m">
                  {{ translateUcFirst("ecommerce.cart.quantity") }}:
                  {{ translateNumber(item.quantity) }}
                </span>
              </slot>
            </div>
            <div class="flex-column text-inline-end">
              <span class="bold">{{
                translateNumber(item.grandTotal, "currency", {
                  currency: item.cartItem.Product.currency,
                })
              }}</span>
              <slot
                name="cart-item-unit-price"
                v-bind="{ item, product: item.cartItem.Product }">
                <span class="text-xs"
                  >{{
                    translateNumber(item.unitPrice, "currency", {
                      currency: item.cartItem.Product.currency,
                    })
                  }}
                  / {{ translate("ecommerce.cart.unit") }}
                </span>
              </slot>
            </div>
          </div>
        </slot>
      </div>

      <div
        v-if="isEmpty"
        class="no-items padding-max-vertical padding-m-horizontal text-center">
        <h3 class="block text-center margin-remove">
          {{ translateUcFirst("ecommerce.cart.cartEmptyExplain") }}
        </h3>
      </div>
    </div>

    <card
      v-if="showActionBar"
      class="flex-column cart-footer padding-remove box-shadow-2 bg-inverse c-lead z-level-2 relative">
      <template #body>
        <div class="card__body margin-remove flex-center flex-middle padding-m">
          <slot name="summary-action">
            <block class="flex gap-m flex-center width-expand flex-wrap">
              <!--
              <form-button icon="cart" text="Review Cart" theme="bg"></form-button> -->
              <form-button
                icon-end="chevron-inline-end"
                :disabled="isEmpty"
                text="ecommerce.cart.checkoutCta"
                theme="lead"
                @click="$router.push({ name: 'store-checkout' })"></form-button>
            </block>
          </slot>
        </div>
      </template>
    </card>
    <div v-else="" class="pseudo-action-bar" style=""></div>
  </div>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object,
      default: () => ({}),
    },
    quote: {
      type: [Object, Boolean],
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showHeaderFeatures: {
      type: Boolean,
      default: true,
    },
    showActionBar: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      messagesExpanded: true,
      messages: [
        {
          content: "Pay offline",
          icon: "wallet",
        },
        {
          content: "We guarantee to facilitate your purchase",
          icon: "shield-check",
        },
      ],
    };
  },
  computed: {
    isEmpty() {
      return this.totalItemQuantity < 1;
    },
    totalItemQuantity() {
      if (this.computedQuote) {
        return this.computedQuote.items.reduce((total, item) => {
          return +total + item.quantity;
        }, 0);
      } else {
        return 0;
      }
    },
    computedQuote() {
      if (this.quote) {
        return this.quote;
      }

      return this.cart.quote;
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-wrapper {
  .cart-header__features {
    border-inline-start: 1px solid var(--c-bg-4);
    border-inline-end: 1px solid var(--c-bg-4);
  }

  .items-container {
    border-inline-start: 1px solid var(--c-bg-4);
    border-inline-end: 1px solid var(--c-bg-4);
  }
  &.no-header .items-container {
    border-top: 1px solid var(--c-bg-4);
  }

  .cart-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none !important;
  }

  .cart-item-unit-controls {
    max-width: 150px;
  }

  .cart-item {
    border-bottom: 1px solid var(--c-gray-2);

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(2n) {
      background-color: var(--c-gray-1);
    }
  }

  .cart-item__image {
    object-fit: contain;
    max-width: 15vw;
    max-height: 150px;
  }

  .cart-item__desc-row {
    //align-items: center;
  }

  &.break-xs {
    .cart-item__image {
      border: 1px solid var(--c-bg-4);
      max-width: 14vw;
      max-height: 130px;
    }
  }

  .pseudo-action-bar {
    border-top: 1px solid var(--c-bg-4);
  }
}
</style>
<style lang="scss"></style>
